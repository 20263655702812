









import {
  Component, Prop, Watch,
} from 'vue-property-decorator';

import {
  Events, isForm, updateNode, walk,
} from '@/helpers/dom';
import { BaseComponent } from '@/components/BaseComponent';
import { matchesPolyfill } from '@/helpers/polyfills';

@Component
export default class Frame extends BaseComponent {
  @Prop({ type: String, default: '' }) readonly styles!: string;

  @Prop({ type: String, required: true }) readonly content!: string;

  get source() {
    return `${this.publicPath}blank.html`;
  }

  events = new Events();

  document: Document | null = null

  beforeDestroy() {
    this.events.clear();
  }

  @Watch('content', { immediate: true })
  changeFrameSource() {
    this.updateDocument();
  }

  private updateDocument() {
    if (this.document === null) return;
    this.events.clear();
    const newDocument = this.createDocumentFromString();
    updateNode(this.document, newDocument);
    this.addHandlers(this.document);
  }

  private createDocumentFromString() {
    const parser = new DOMParser();
    const document = parser.parseFromString(this.content, 'text/html');
    const style = document.createElement('style');
    style.textContent = this.styles;
    document.head.appendChild(style);
    return document;
  }

  onLoadFrame() {
    const frame = this.$refs.frame as HTMLIFrameElement;
    const window = frame.contentWindow;
    const currentDocument = this.document;
    if (window) {
      this.document = window.document;
      matchesPolyfill(window);
      if (currentDocument == null) {
        this.updateDocument();
      } else {
        this.addHandlers(this.document);
      }
      this.$emit('frame-load', window);
    }
  }

  addHandlers(document: Document) {
    this.handleLinks(document);
    this.handleForms(document);
  }

  private handleLinks(document: Document) {
    walk(document.body, (element) => {
      const isHtml = element.hasAttribute('href');
      const isXml = element.hasAttribute('xlink:href');
      if (isHtml || isXml) {
        this.events.add(element, 'click', (event) => {
          event.preventDefault();
          const attribute = isHtml ? element.getAttribute('href') : element.getAttribute('xlink:href');
          this.$emit('action', {
            id: attribute,
            element,
          });
        });
      }
    });
  }

  private handleForms(document: Document) {
    this.events.add(document.body, 'submit', (event) => {
      const { target } = event;
      if (isForm(target)) {
        event.preventDefault();
        const action = target.getAttribute('action');
        if (action) {
          this.$emit('action', {
            id: action,
            element: target,
          });
        }
      }
    });
  }
}
